import React from 'react'
import Datetime from 'react-datetime';
import Select from 'react-select'
import Creatable, { makeCreatableSelect } from 'react-select/creatable';

import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faPlane,
  faPlaneDeparture,
  faPlaneArrival,
  faMobileAlt,
} from '@fortawesome/free-solid-svg-icons';

import {
  faClock,
} from '@fortawesome/free-regular-svg-icons';

// Padding between input fields
const padding = 'pa2 pb3'

export function AircraftInput(props){
  if(props.availableAircraft.length > 0){
    return(<SelectableInput {...props} />)
  }else {
    return(<Input name='aircraft' label='Aircraft' type='text' icon={faPlane} onChange={props.onChange} />)
  }
}

function SelectableInput(props){
  return(
    <div className={padding} data-testid='aircraft-select-input'>
      <div className="measure">
        <InputLabel icon={faPlane} name='Aircraft' />

        <Creatable name='Aircraft' type='text' id='aircraft'
          options={props.availableAircraft}
          formatCreateLabel={(value) => { return value } }
          onChange={props.onChange}
          value={props.value}
          onBlur={props.onBlur}
          {...props}
        />
      </div>
    </div>
  )
}
export function PhoneInput(props){ return(<Input name='phone_number' label='Phone' type='tel' icon={faMobileAlt} value={props.phone_number} onChange={props.onChange} />) }
export function StageTimeInput(props){ return(<DateInput id='stage-time' name='Stage Time' icon={faClock} initialValue={props.initialValue} onChange={props.onChange} />) }
export function DepartureTimeInput(props){ return(<DateInput id='departure-time' name='Departure Time' icon={faPlaneDeparture} initialValue={props.initialValue} onChange={props.onChange} />) }
export function ArrivalTimeInput(props){ return(<DateInput id='arrival-time' name='Arrival Time' icon={faPlaneArrival} initialValue={props.initialValue} onChange={props.onChange} />) }
export function CommentBox(props){ return(<TextArea name='Comments' onChange={props.onChange}/>) }
export function SubmitButton(props){
  return(<input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 bg-washed-blue" type="submit" value={props.value} onClick={props.formHandler} data-testid={'form-submit'} />)
}

function InputLabel(props){
  const icon = props.icon ? <FontAwesomeIcon className='pr2' icon={props.icon} /> : null

  return(
    <div className='flex justify-center'>
      {icon}
      <label htmlFor={props.name} className="f6 b db mb2">{props.name}</label>
    </div>
  )
}

function Input(props){
  return(
    <div className={padding}>
      <div className="measure">
        <InputLabel icon={props.icon} name={props.label}/>
        <input name={props.name}
          id={props.name}
          label={props.label}
          type={props.type}
          className="input-reset ba b--black-20 pa2 mb2 db w-100"
          onChange={props.onChange}
          defaultValue={props.value}
          data-testid={props.name + '-text-input'}
      />
      </div>
    </div>
  )
}

function DateInput(props) {
    return(
      <div className={padding}>
          <div className="measure">
            <InputLabel icon={props.icon} name={props.name} />
            <Datetime
              id={props.id}
              timeConstraints={ { minutes: { step: 15 } } }
              initialViewDate={props.initialValue}
              inputProps={{ className: "input-reset ba b--black-20 pa2 mb2 db w-100", 'data-testid': props.id + '-date-input' }}
              onChange={props.onChange}
            />
          </div>
      </div>
    )
}

function TextArea(props){
  return(
    <div className={padding}>
      <div className="measure">
        <InputLabel icon={props.icon} name={props.name} />
        <textarea id={props.name} name={props.name} className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2" onChange={props.onChange} data-testid={props.name + "-textarea"}></textarea>
      </div>
    </div>
  )
}

