import React from 'react'

export default function InfoTable(props){
  const rows = []

  Object.keys(props.data).forEach((key) => {
    rows.push(
      <tr key={key}>
        <td className="pv3 pr3 bb b--black-20">{key}</td>
        <td className='pv3 pr3 bb b--black-20'>{props.data[key]}</td>
      </tr>
    )
  })

  return(
    <div className="overflow-auto">
      <table className="f6 w-100 mw6 center" cellSpacing="0">
        <tbody className="lh-copy">
          {rows}
        </tbody>
      </table>
    </div>
  )
}
