import React from 'react'
import PageTitle from './PageTitle'
import moment from 'moment';

import { AircraftInput, PhoneInput, StageTimeInput, DepartureTimeInput, ArrivalTimeInput, CommentBox, SubmitButton } from './inputs/Inputs'

import { ApiRequest } from './utils/api_request'

class RequestForm extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      availableAircraft: props.aircraft,
      selectedAircraft: this.defaultAircraft(),
      phone_number: props.phone_number,
      stage_time: '',
      departure_time: '',
      arrival_time: '',
      comments: '',
      errors: [],
    }
  }

  defaultAircraft = () => {
    if(!this.props.user) { return }
    return this.props.user.employee ? {type: '', key: ''} : this.props.aircraft[0]
  }

  render(){
    return(
      <div className="flex flex-column items-center">
        <PageTitle text='Mid Island Service Request' />

        <Errors errors={this.state.errors} />
        <form className='pa3 mr2 w-20-ns' onSubmit={this.onSubmit}>
          <AircraftInput availableAircraft={this.state.availableAircraft} onChange={this.onSelectChange} onBlur={this.onSelectBlur} value={this.state.selectedAircraft} />
          <PhoneInput phone_number={this.props.phone_number} onChange={this.onPhoneChange} />
          <StageTimeInput onChange={this.onStageChange} initialValue={this.minutesFromNow(30)} />
          <DepartureTimeInput onChange={this.onDepartureChange} initialValue={this.minutesFromNow(60)} />
          <ArrivalTimeInput onChange={this.onArrivalChange} initialValue={this.minutesFromNow(180)} />
          <CommentBox onChange={this.onCommentChange} />

          <SubmitButton value='Create Request' onSubmit={this.onSubmit} />
        </form>
      </div>
    )
  }


  onSelectChange = (event) => {
    if(typeof event.target == 'object'){
      this.setState({ selectedAircraft: event.target.value })
    } else if (typeof event == 'object') {
      this.setState({ selectedAircraft: { ...event } })
    } else {
      this.setState({ selectedAircraft: event.target.value })
    }
  }

  onSelectBlur = (event) => {
    if(event.target.value === '') { return }
    this.setState({ selectedAircraft: { value: event.target.value, label: event.target.value } })
  }

  onPhoneChange = (event) => { this.setState({ phone_number: event.target.value }) }
  onStageChange = (data) => { this.onDateChange('stage_time', data) }
  onDepartureChange = (data) => { this.onDateChange('departure_time', data) }
  onArrivalChange = (data) => { this.onDateChange('arrival_time', data) }
  onCommentChange = (event) => { this.setState({ comments: event.target.value }) }

  onDateChange = (name, value) => {
    const date = moment.isMoment(value) ? value.toDate().toISOString() : value
    this.setState({[name]: date })
  }

  errorHandler = (errors) => {
    this.setState({ errors: errors })
  }

  onSubmit = (event) => {
    event.preventDefault();
    let params = {
      phone_number: this.state.phone_number,
      stage_time: this.state.stage_time,
      departure_time: this.state.departure_time,
      arrival_time: this.state.arrival_time,
      comments: this.state.comments,
    }
    params.aircraft = (this.state.selectedAircraft instanceof Object) ? this.state.selectedAircraft.value : this.state.selectedAircraft

    console.log('submitting:');
    console.log(params);
    ApiRequest.call('requests', { request: params }, this.errorHandler )
  }


  // Returns a Date object $minutes offset from the current time and rounded up to the nearest 15
  // eg calling the function at 10:33 will return 11:45
  minutesFromNow = (minutes) => {
    const offsetMinutes = minutes ? minutes : 60
    let offsetDate = new Date(new Date().getTime() + offsetMinutes * 60000);
    return offsetDate.setMinutes(Math.ceil(offsetDate.getMinutes() / 15) * 15)
  }
}

function Errors(props){
  const errorMessages = props.errors.map((error, key) => <ErrorMessage key={key} error={error} /> )
  return(
    <div className='red'>
      {errorMessages}
    </div>
  )
}
function ErrorMessage(props){ return(<div className='red'>{props.error}</div>) }

export default RequestForm
