import React, { useState, useEffect } from "react"
import actionCable from 'actioncable'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faSortUp, faPlaneDeparture, faPlaneArrival, faRemoveFormat } from '@fortawesome/free-solid-svg-icons'

const App = {}
const protocol = (location.protocol == 'http:') ? 'ws://'  : 'wss://'
App.cable = actionCable.createConsumer(protocol + location.hostname+(location.port ? ':'+location.port: '') + '/cable')

const VERTICAL_LAYOUT = 'flex-column'
const VERTICAL_WIDTH = 'w-100 pa2'

const HORIZONTAL_LAYOUT = 'flex-row'
const HORIZONTAL_WIDTH = 'w-50 pa2'

class Dashboard extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      requests: [],
      departures: [],
      arrivals: [],
      layout: VERTICAL_LAYOUT,
      layoutWidth: VERTICAL_WIDTH,
      showComments: true,
    }
  }

  componentDidMount(){
    this.requestsChannel = App.cable.subscriptions.create(
      { channel: 'RequestsChannel', },
      {
        connected: () => { console.log('Connected'); this.loadRequests(); },
        disconnected: () => { console.log('Disconnected') },
        received: (message) => { console.log("RX data"); this.processMessage(message) },
        load: () => { console.log('Loading'); this.requestsChannel.perform('load') },
        subscription: () => { console.log('Subscription') },
      }
    )
  }

  loadRequests = () => { App.cable.subscriptions.subscriptions[0].load(); }
  processMessage = (message) => {
    switch(message.type){
      case 'requests':
        this.setState({departures: message.payload.departures, arrivals: message.payload.arrivals});
        break;
      case 'new_departure':
        this.setState({departures: this.state.departures.concat(message.payload)})
        break;
      case 'new_arrival':
        this.setState({arrivals: this.state.arrivals.concat(message.payload)})
        break;
      default:
        console.log('Unknown message type:', message.type);
    }
  }

  setLayout = (event) => {
    if(event.target.value == 'horizontal') {
      this.setState({
        layout: HORIZONTAL_LAYOUT,
        layoutWidth: HORIZONTAL_WIDTH,
        showComments: false,
        dateWidth: 'w-40',
      })
    } else {
      this.setState({
        layout: VERTICAL_LAYOUT,
        layoutWidth: VERTICAL_WIDTH,
        showComments: true,
        dateWidth: 'w-20',
      })
    }
  }

  render(){
    return (
      <>
        <select onChange={ (event) => { this.setLayout(event) } }>
          <option value='vertical'>Vertical</option>
          <option value='horizontal'>Horizontal</option>
        </select>
        <div className={'flex ' + this.state.layout} >
          <Departures requests={this.state.departures} layout={this.state.layout} layoutWidth={this.state.layoutWidth} showComments={this.state.showComments} dateWidth={this.state.dateWidth} />
          <Arrivals requests={this.state.arrivals} layout={this.state.layout} layoutWidth={this.state.layoutWidth} showComments={this.state.showComments} dateWidth={this.state.dateWidth} />
        </div>
      </>
    );
  }
}

function ColumnTitle(props){ return(<h1 className='f3'>{props.text}</h1>) }

class Departures extends React.Component {
  constructor(props){
    super(props)
    this.state = { sortType: 'departureDown', stageIcon: faSortDown, departureIcon: faSortDown }
  }

  changeDepartureSortType = (event) => {
    event.preventDefault();
    let newSortType = this.state.sortType == 'departureDown' ? 'departureUp' : 'departureDown'
    let newDepartureIcon = this.state.departureIcon == faSortDown ? faSortUp : faSortDown
    this.setState({ sortType: newSortType, departureIcon: newDepartureIcon  })
  }

  changeStageSortType = (event) => {
    event.preventDefault();
    let newSortType = this.state.sortType == 'stageDown' ? 'stageUp' : 'stageDown'
    let newStageIcon = this.state.stageIcon == faSortDown ? faSortUp : faSortDown
    this.setState({ sortType: newSortType, stageIcon: newStageIcon })
  }

  sortTypes = {
    stageDown: { fn: (a, b) => { return new Date(b.stage_time) - new Date(a.stage_time) } },
    stageUp: { fn: (a, b) => { return new Date(a.stage_time) - new Date(b.stage_time) } },
    departureDown: { fn: (a, b) => { return new Date(b.departure_time) - new Date(a.departure_time) } },
    departureUp: { fn: (a, b) => { return new Date(a.departure_time) - new Date(b.departure_time) } },
  }

  commentsColumn = () => {
    return(
      this.props.showComments ? <th className='fw8 tc bg-white w-50'>Comments</th> : null
    )
  }

  render() {
    return(
      <div className={this.props.layoutWidth}>
        <ColumnTitle text='Departures' />

        <table className="f6 w-100 center dt" cellSpacing="0">
          <thead>
            <tr className="stripe-dark">
              <th className="fw8 tc bg-white w-5">Aircraft</th>
              <th key='2' className={'fw8 tc bg-white ' + this.props.dateWidth} >
                <a onClick={this.changeStageSortType} className='black no-underline' href='#'>
                  Stage Time
                  <FontAwesomeIcon className='pl2' icon={this.state.stageIcon} />
                </a>
              </th>
              <th className={'fw8 tc bg-white ' + this.props.dateWidth }>
                <a key='3' onClick={this.changeDepartureSortType} className='black no-underline' href='#'>
                  Departure Time
                  <FontAwesomeIcon className='pl2' icon={this.state.departureIcon} />
                </a>
              </th>
              {this.commentsColumn()}
            </tr>
          </thead>

          <tbody className="lh-copy f4">
            <NoResultsRow data={this.props.requests} message='no outstanding departures' />
            { [...this.props.requests].sort(this.sortTypes[this.state.sortType].fn).map((request, i) => {
              if(request.visible_on_dashboard === true) {
                return (<DashboardRow key={i} data={request} icon={faPlaneDeparture} departure={true} showComments={this.props.showComments}/>)
              }
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

function getColor(input){
  switch(input){
    case 'new':
      return 'bg-washed-yellow'
      break;
    case 'acknowledged':
      return 'bg-washed-yellow'
      break;
    case 'completed':
      return 'bg-light-green'
      break;
    case 'rejected':
      return 'bg-light-red'
      break;
  }
}

function followLink(event, url){
  let classes = event.target.getAttribute('class')
  if(!classes){ classes = event.target.parentElement.getAttribute('class') }
  if(classes.match(/dont\-follow\-link/)){ return };

  window.location.href = url
}

class Arrivals extends React.Component {
  constructor(props){
    super(props)
    this.state = { sortType: 'arrivalUp', arrivalIcon: faSortUp, departureIcon: faSortUp }
  }

  sortTypes = {
    arrivalDown: { fn: (a, b) => { return new Date(b.arrival_time) - new Date(a.arrival_time) } },
    arrivalUp: { fn: (a, b) => { return new Date(a.arrival_time) - new Date(b.arrival_time) } },
    departureDown: { fn: (a, b) => { return new Date(b.departure_time) - new Date(a.departure_time) } },
    departureUp: { fn: (a, b) => { return new Date(a.departure_time) - new Date(b.departure_time) } },
  }

  changeDepartureSortType = (event) => {
    event.preventDefault();
    const newSortType = this.state.sortType == 'departureDown' ? 'departureUp' : 'departureDown'
    let newIcon = this.state.departureIcon == faSortDown ? faSortUp : faSortDown
    this.setState({ sortType: newSortType, departureIcon: newIcon })
  }

  changeArrivalSortType = (event) => {
    event.preventDefault();
    const newSortType = this.state.sortType == 'arrivalDown' ? 'arrivalUp' : 'arrivalDown'
    const newIcon = this.state.arrivalIcon == faSortDown ? faSortUp : faSortDown
    this.setState({ sortType: newSortType, arrivalIcon: newIcon })
  }

  commentsColumn = () => {
    return(this.props.showComments ? <th className='fw8 tc bg-white w-50'>Comments</th> : null)
  }

  render() {
    return(
      <div className={this.props.layoutWidth}>
        <ColumnTitle text='Arrivals' />

        <table className="f6 w-100 center dt" cellSpacing="0">
          <thead>
            <tr className="stripe-dark">
              <th className="fw8 tc bg-white w-5">Aircraft</th>
              <th className={'fw8 tc bg-white ' + this.props.dateWidth} >
                <a onClick={this.changeArrivalSortType} className='black no-underline' href='#'>
                  Arrival Time
                  <FontAwesomeIcon className='pl2' icon={this.state.arrivalIcon} />
                </a>
              </th>
              <th className={'fw8 tc bg-white ' + this.props.dateWidth} >
                <a onClick={this.changeDepartureSortType} className='black no-underline' href='#'>
                  Departure Time
                  <FontAwesomeIcon className='pl2' icon={this.state.departureIcon} />
                </a>
              </th>
              {this.commentsColumn()}
            </tr>
          </thead>
          <tbody className="lh-copy f4">
            <NoResultsRow data={this.props.requests} message='no outstanding arrivals' />
            {
              [...this.props.requests].sort(this.sortTypes[this.state.sortType].fn).map((request, i) => {
                if(request.visible_on_dashboard === true) { return (<DashboardRow key={i} data={request} icon={faPlaneArrival} arrival={true} showComments={this.props.showComments} />) }
              })
            }
          </tbody>
        </table>
      </div>
    )
  }
}

function NoResultsRow(props) {
  if(props.data.length == 0) {
    return(<tr><td className='pa3 pt5 i f5' colSpan='6'>({props.message})</td></tr>)
  } else {
    return null;
  }
}

const DashboardRow = (props) => {
  const commentField = props.showComments ? <td className='bb b--black-20'>{props.data.comments}</td> : null
  let dataFields = null

  const [backgroundColor, setBackgroundColor] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if(props.data.workflow_state == 'new') {
        setBackgroundColor((backgroundColor) => { return backgroundColor ? null : 'white' })
      } else {
        setBackgroundColor(null)
        clearInterval(interval)
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [props.data.workflow_state]);

  if(props.departure){
    dataFields = <>
      <td className='bb b--black-20'><FormatedDate data={props.data.stage_time} /></td>
      <td className='bb b--black-20'><FormatedDate data={props.data.departure_time} /></td>
      {commentField}
    </>
  } else if(props.arrival) {
    dataFields = <>
      <td className='bb b--black-20'><FormatedDate data={props.data.arrival_time} /></td>
      <td className='bb b--black-20'><FormatedDate data={props.data.departure_time} /></td>
      {commentField}
    </>
  }

  return(
    <tr className={getColor(props.data.workflow_state) + ' pointer'} style={ { backgroundColor: backgroundColor } } onClick={(event) => { followLink(event, props.data.url) } }>
      <td className='bb b--black-20'>{props.data.aircraft}</td>

      {dataFields}

      <td className='bb b--black-20 dont-follow-link' onClick={() => { sendPatch(props.data.api_url + '/clear_from_dashboard') }}>
        <FontAwesomeIcon title="Remove from dashboard" className='dont-follow-link' icon={faRemoveFormat} size="xs" />
      </td>
      <td className='bb b--black-20 dont-follow-link' onClick={() => { window.location = props.data.flight_aware_url } }>
        <FontAwesomeIcon title='View on Flight Aware' className='pr2 dont-follow-link' icon={props.icon} size='xs' />
      </td>
    </tr>
  )
}

const sendPatch = (url) => {
  fetch(url,
      {
        method: 'PATCH',
        headers: { "Content-type": "application/json"  }
      }
  )
}

const FormatedDate = ({data}) => {
  if(data == null){ return null };
  const date = new Intl.DateTimeFormat("default", {
    month: "2-digit",
    day: "2-digit",
  }).format(new Date(data))

  const time = new Intl.DateTimeFormat("default", {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  }).format(new Date(data))

  return(<span>{date} {time}</span>)
}

export default Dashboard
