const API_PATH = '/api/v1/';

export class ApiRequest {
  static call(endpoint, data, errorHandler){
    fetch(API_PATH + endpoint, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
      return response.json()
    })
    .then((response) => {
      if(response.errors) { errorHandler(response.errors) }
      if(response.redirect_to){ window.location = response.redirect_to }
      console.log(response)
    })
  }
}
