import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlaneDeparture, faExclamation, faCheck, faTimes, faRemoveFormat, faChalkboard } from '@fortawesome/free-solid-svg-icons'

const STATUS_BUTTON_CLASSES = 'f6 link br1 ba bw1 ph3 pv2 mb2 dib black mt3 w5 pointer b--black'
const ACKNOWLEDGE_COLOR = 'bg-washed-yellow'
const COMPLETE_COLOR = 'bg-washed-green'
const REJECT_COLOR = 'bg-light-red'

function Button(props){
  return(
    <>
      <button {...props} className={STATUS_BUTTON_CLASSES + ' ' + props.className} >
        <FontAwesomeIcon className='pl2' icon={props.icon} /> {props.title}
      </button>
      <br />
    </>
  )
}

export function EditButton(props){
  return( <Button icon={faEdit} {...props} onClick={() => { window.location = props.path + '/edit' } } />)
}

export function FlightRadarButton(props) {
  return( <Button icon={faPlaneDeparture} className='bg-gold' onClick={() => { window.location = 'https://flightaware.com/live/flight/' + props.aircraft} } title='Flight Radar' />)
}

export function AcknowledgeButton(props) {
  return( <Button onClick={() => { sendPatch(props.path + '/acknowledge') } } title='Acknowledge' className={ACKNOWLEDGE_COLOR} icon={faExclamation} />)
}

export class CompleteButton extends React.Component{
  state = {
    showComments: false,
    comments: ''
  }

  toggleVisiblity = () => { this.setState({ showComments: !this.state.showComments }) }
  updateComments = (event) => { this.setState({comments: event.target.value}); }

  render(){
    const commentsBox = this.state.showComments ? <><textarea onChange={this.updateComments} className='border-box hover-black measure ba b--black-20 pa2 br2 mb2' />< br /></> : null
    return(
      <>
        <Button title='Complete' className={COMPLETE_COLOR} icon={faCheck} onClick={() => { sendPatch(this.props.path + '/complete', this.state.comments) } }/>

        <input onClick={this.toggleVisiblity} className="mr2" type="checkbox" id="complete_comments" value="complete_comments" />
        <label htmlFor="complete_comments" className="lh-copy">Add comments</label>
        <br />
        {commentsBox}
      </>
    )
  }
}

export function RejectButton(props) {
  const title = props.employee ? 'Reject' : 'Cancel';

  return(
    <Button onClick={() => { sendPatch(props.path + '/reject') } } title={title} className={REJECT_COLOR} icon={faTimes} />
  )
}

export function DashboardVisibilityButton(props){
  if(props.visible){
    return(<ClearFromDashboardButton {...props} />)
  } else {
    return(<AddToDashboardButton {...props} />)
  }
}

export function ClearFromDashboardButton(props){
  return(
    <Button onClick={() => { sendPatch(props.path + '/clear_from_dashboard') } } title='Clear From Dashboard' className='bg-light-purple' icon={faRemoveFormat} />
  )
}

export function AddToDashboardButton(props){
  return(
    <Button onClick={() => { sendPatch(props.path + '/add_to_dashboard') } } title='Add To Dashboard' className='bg-light-purple' icon={faChalkboard} />
  )
}

function sendPatch(url, comments = ''){
  fetch(url, {
    method: 'PATCH',
    body: JSON.stringify({ request: { comments: comments } }),
    headers: {
      "Content-type": "application/json"
    }
  })
  .then((response) => {
    return response.json();
  })
  .then((data) => {
    window.location = data.url
  })
}
