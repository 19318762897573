import React from 'react'
import PageTitle from './PageTitle'
import InfoTable from './InfoTable'
import { EditButton, FlightRadarButton, AcknowledgeButton, CompleteButton, RejectButton, DashboardVisibilityButton } from './Buttons'

export default class RequestInfo extends React.Component {
  render(){
    return(
      <>
        <PageTitle text='Request Info' />
        <InfoTable data={this.props.request_details} />
        <RequestInfoButtons user={this.props.user.data.attributes} request={this.props.request.data.attributes} />
      </>
    )
  }
}

function RequestInfoButtons(props){
  const buttons = []

  if(!props.request['rejected?'] || !props.request['completed?']) {
      if (props.request['new?'] && props.user.employee) {
          buttons.push(<AcknowledgeButton path={props.request.api_url} key='ack'/>)
      }
      if (props.request['acknowledged?'] && props.user.employee) {
          buttons.push(<CompleteButton path={props.request.api_url} key='complete'/>)
      }
    if(props.request['new?'] || props.request['acknowledged?']){ buttons.push(<RejectButton path={props.request.api_url} employee={props.user.employee} key='reject'/>) }
  };
  if(props.user.employee){
      buttons.push(<DashboardVisibilityButton visible={props.request.visible_on_dashboard} path={props.request.api_url}
                                              key='dash'/>)
  }

  return(
    <>
      <EditButton path={props.request.url} title='Edit Request'/>
      <FlightRadarButton aircraft={props.request.aircraft} />
      <h1 className='f4 lh-copy'>Actions</h1>
      {buttons}
    </>
  )
}

function sendPatch(url, comments = ''){
  fetch(url, { method: 'PATCH', body: JSON.stringify({ request: { comments: comments } }), headers: { "Content-type": "application/json"  } })
  .then((response) => {
    return response.json();
  })
  .then((data) => {
    window.location = data.url
  })
}

